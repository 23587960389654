@import '../../../../../../styles/shared';

.c-terms-conditions-text {
  @include margin(0, 0, 16px, 0);

  &--error {
    @include margin(0, 0, 18px, 18px);
    font-weight: bold;
    color: #c9474d;
  }
}
